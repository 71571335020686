import { HiOutlineVolumeUp } from '@react-icons/all-files/hi/HiOutlineVolumeUp';
import { defineType } from '@sanity/types';

import { Document, Image } from './_types';
import { ksuidIdField } from './_utils';
import { BlockContentType } from './block/_types';
import { MediaItemVariantType } from './MediaItemVariant';
import { MediaTagType } from './MediaTag';

export type MediaItemType = Document & {
	_type: 'MediaItem';
	id: string;
	name: string;
	description: BlockContentType;
	isEroticStory: boolean;
	image?: Image;
	displayedTag?: MediaTagType;
	tags: MediaTagType[];
	variants: MediaItemVariantType[];
	defaultMediaItemVariant: MediaItemVariantType;
};

export default defineType({
	name: 'MediaItem',
	type: 'document',
	icon: HiOutlineVolumeUp,
	fields: [
		ksuidIdField('mediaitem'),
		{
			name: 'name',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'description',
			type: 'reference',
			to: [{ type: 'BlockContentWrapper' }],
			validation: (r) => r.required(),
		},
		{
			name: 'image',
			type: 'image',
			description:
				'Make sure to set the hotspot by clicking the crop icon on the image',
			options: {
				// https://www.sanity.io/docs/image-type#hotspot-3e6da78954a8
				hotspot: true,
			},
		},
		{
			name: 'isEroticStory',
			type: 'boolean',
			description: 'Whether the media item is an erotic story',
			initialValue: false,
		},
		{
			name: 'displayedTag',
			type: 'reference',
			to: [{ type: 'MediaTag' }],
			description: 'The tag that will be displayed in the library',
			validation: (r) =>
				r
					.custom((value: MediaTagType, context) => {
						const doc = context.document as MediaItemType;
						if (!doc?.tags?.some((tag) => tag.key === value.key)) {
							return 'Tag must be in the tags array';
						}
						return true;
					})
					.optional(),
		},
		{
			name: 'tags',
			type: 'array',
			of: [{ type: 'reference', to: [{ type: 'MediaTag' }] }],
			validation: (r) => r.unique(),
		},
		{
			name: 'variants',
			type: 'array',
			of: [{ type: 'reference', to: [{ type: 'MediaItemVariant' }] }],
		},
		{
			name: 'defaultMediaItemVariant',
			type: 'reference',
			to: [{ type: 'MediaItemVariant' }],
			validation: (r) => r.required(),
		},
	],
	preview: {
		select: {
			name: 'name',
			variants: 'variants',
			defaultVariant: 'defaultMediaItemVariant',
		},
		prepare({ name, variants, defaultVariant }) {
			const numberOfVariants = variants?.length || defaultVariant ? 1 : 0;
			return {
				title: name,
				subtitle: `${numberOfVariants} variant${numberOfVariants === 1 ? '' : 's'}`,
			};
		},
	},
});
