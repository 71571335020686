import { Document } from '../_types';
import { ExpressionBooleanType } from '../expressions/_types';
import { FlowAudioPlayerStepType } from './FlowAudioPlayerStep';
import { FlowCalendlyBookingStepType } from './FlowCalendlyBookingStep';
import { FlowCheckInQuestionsStepType } from './FlowCheckInQuestionsStep';
import { FlowChooseTherapistStepType } from './FlowChooseTherapistStep';
import { FlowCommunityTopicStepType } from './FlowCommunityTopicStep';
import { FlowContentStepType } from './FlowContentStep';
import { FlowEfficacyMeasurementStepType } from './FlowEfficacyMeasurementStep';
import { FlowExerciseRESupportStepType } from './FlowExerciseRESupportStep';
import { FlowExerciseTimedBoxBreathAnimationStepType } from './FlowExerciseTimedBoxBreathAnimationStep';
import { FlowExerciseTimedContentStepType } from './FlowExerciseTimedContentStep';
import { FlowExerciseTimedImageStepType } from './FlowExerciseTimedImageStep';
import { FlowFullScreenVideoStepType } from './FlowFullScreenVideoStep';
import { FlowInputStepType } from './FlowInputStep';
import { FlowItoChatStepType } from './FlowItoChatStep';
import { FlowItoEfficacyMeasurementStepType } from './FlowItoEfficacyMeasurementStep';
import { FlowItoInnerCriticVisualizeStepType } from './FlowItoInnerCriticVisualizeStep';
import { FlowItoLoadingAnimationStepType } from './FlowItoLoadingAnimationStep';
import { FlowItoNotificationsStepType } from './FlowItoNotificationsStep';
import { FlowItoPlanStepType } from './FlowItoPlanStep';
import { FlowItoPlanSummaryStepType } from './FlowItoPlanSummaryStep';
import { FlowItoTextStepType } from './FlowItoTextStep';
import { FlowItoTherapistDetailsStepType } from './FlowItoTherapistDetailsStep';
import { FlowNameStepType } from './FlowNameStep';
import { FlowNotificationsPreferenceStepType } from './FlowNotificationsPreferenceStep';
import { FlowOnboardingActivityStepType } from './FlowOnboardingActivityStep';
import { FlowOnboardingGoalsStepType } from './FlowOnboardingGoalsStep';
import { FlowPaywallStepType } from './FlowPaywallStep';
import { FlowPelvicExerciseStepType } from './FlowPelvicExerciseStep';
import { FlowPelvicFloorExerciseCompleteStepType } from './FlowPelvicFloorExerciseCompleteStep';
import { FlowPlanStrategiesStepType } from './FlowPlanStrategiesStep';
import { FlowPollQuestionAnswerStepType } from './FlowPollQuestionAnswerStep';
import { FlowPollSliderStepType } from './FlowPollSliderStep';
import { FlowPollStepType } from './FlowPollStep';
import { FlowReverseKegelExerciseStepType } from './FlowReverseKegelExerciseStep';
import { FlowSWScoreOnboardingResultStatStepType } from './FlowSWScoreOnboardingResultStatStep';
import { FlowSWScoreOnboardingResultStepType } from './FlowSWScoreOnboardingResultStep';
import { FlowSWScoreOnboardingStepType } from './FlowSWScoreOnboardingStep';
import { FlowWrapUpStepType } from './FlowWrapUpStep';

export const flowStepTypes = [
	'FlowAudioPlayerStep',
	'FlowCalendlyBookingStep',
	'FlowChooseTherapistStep',
	'FlowCommunityTopicStep',
	'FlowContentStep',
	'FlowCheckInQuestionsStep',
	'FlowEfficacyMeasurementStep',
	'FlowExerciseRESupportStep',
	'FlowExerciseTimedBoxBreathAnimationStep',
	'FlowExerciseTimedContentStep',
	'FlowExerciseTimedImageStep',
	'FlowFullScreenVideoStep',
	'FlowInputStep',
	'FlowItoChatStep',
	'FlowItoEfficacyMeasurementStep',
	'FlowItoInnerCriticVisualizeStep',
	'FlowItoLoadingAnimationStep',
	'FlowItoNotificationsStep',
	'FlowItoPlanStep',
	'FlowItoPlanSummaryStep',
	'FlowItoTextStep',
	'FlowItoTherapistDetailsStep',
	'FlowNameStep',
	'FlowNotificationsPreferenceStep',
	'FlowOnboardingActivityStep',
	'FlowOnboardingGoalsStep',
	'FlowPaywallStep',
	'FlowPelvicExerciseStep',
	'FlowPelvicFloorExerciseCompleteStep',
	'FlowPlanStrategiesStep',
	'FlowPollQuestionAnswerStep',
	'FlowPollSliderStep',
	'FlowPollStep',
	'FlowReverseKegelExerciseStep',
	'FlowSWScoreOnboardingResultStatStep',
	'FlowSWScoreOnboardingResultStep',
	'FlowSWScoreOnboardingStep',
	'FlowWrapUpStep',
];

export type FlowStepType =
	| FlowAudioPlayerStepType
	| FlowCalendlyBookingStepType
	| FlowChooseTherapistStepType
	| FlowCommunityTopicStepType
	| FlowContentStepType
	| FlowCheckInQuestionsStepType
	| FlowEfficacyMeasurementStepType
	| FlowExerciseRESupportStepType
	| FlowExerciseTimedBoxBreathAnimationStepType
	| FlowExerciseTimedContentStepType
	| FlowExerciseTimedImageStepType
	| FlowFullScreenVideoStepType
	| FlowInputStepType
	| FlowItoChatStepType
	| FlowItoEfficacyMeasurementStepType
	| FlowItoInnerCriticVisualizeStepType
	| FlowItoLoadingAnimationStepType
	| FlowItoNotificationsStepType
	| FlowItoPlanStepType
	| FlowItoPlanSummaryStepType
	| FlowItoTextStepType
	| FlowItoTherapistDetailsStepType
	| FlowNameStepType
	| FlowNotificationsPreferenceStepType
	| FlowOnboardingActivityStepType
	| FlowOnboardingGoalsStepType
	| FlowPaywallStepType
	| FlowPelvicExerciseStepType
	| FlowPelvicFloorExerciseCompleteStepType
	| FlowPlanStrategiesStepType
	| FlowPollQuestionAnswerStepType
	| FlowPollSliderStepType
	| FlowPollStepType
	| FlowReverseKegelExerciseStepType
	| FlowSWScoreOnboardingResultStatStepType
	| FlowSWScoreOnboardingResultStepType
	| FlowSWScoreOnboardingStepType
	| FlowWrapUpStepType;

export const flowItemTypes = [...flowStepTypes, 'FlowGroup'];
export type FlowItemType = FlowStepType | FlowGroupType;

export type FlowGroupType = Document & {
	_type: 'FlowGroup';
	cases?: FlowGroupCaseType[];
	defaultItems?: FlowItemType[];
};

type FlowGroupCaseType = {
	condition: ExpressionBooleanType[];
	items?: FlowItemType[];
};
