import { HiOutlineDocumentText } from '@react-icons/all-files/hi/HiOutlineDocumentText';
import { getExtension } from '@sanity/asset-utils';
import { SanityImageObject } from '@sanity/image-url/lib/types/types';
import { defineType, Image } from '@sanity/types';

import { previewNamedBlockContent } from '../_utils';
import { FlowBasePageType } from './baseTypes';

export type FlowPaywallStepType = FlowBasePageType & {
	_type: 'FlowPaywallStep';
	items: Item[];
};

type Item = {
	text: string;
	image: Image;
};

export default defineType({
	name: 'FlowPaywallStep',
	type: 'document',
	icon: HiOutlineDocumentText,
	fields: [
		{
			name: 'title',
			type: 'InlineContent',
			validation: (r) => r.length(1),
		},
		{
			name: 'items',
			type: 'array',
			validation: (r) => r.required(),
			of: [
				{
					name: 'item',
					type: 'object',
					fields: [
						{
							name: 'text',
							type: 'string',
							validation: (r) => r.required(),
						},
						{
							name: 'image',
							type: 'image',
							validation: (r) => {
								return r.required().custom<SanityImageObject>((value) => {
									if (value && getExtension(value.asset._ref) !== 'svg') {
										return 'Only SVG images are allowed';
									}
									return true;
								});
							},
						},
					],
					preview: {
						select: {
							title: 'text',
						},
					},
				},
			],
		},
		{
			name: 'nextCtaText',
			type: 'string',
			initialValue: 'Next',
			validation: (r) => r.required(),
		},
	],
	preview: previewNamedBlockContent('title'),
});
