import { getExtension } from '@sanity/asset-utils';
import { SanityImageObject } from '@sanity/image-url/lib/types/types';
import { defineType, Image } from '@sanity/types';

import { ActionType, InlineContentType } from '../..';
import { Document } from '../_types';
import {
	arrayOfTypesBoolean,
	ExpressionBooleanType,
} from '../expressions/_types';
import { renderExpression } from '../expressions/_utils';
import { Actions } from '../fields/actions/Actions';
import { ItoAssistantConfigStub } from '../ItoAssistantConfig';

export enum ChatMenuIcon {
	calendar = 'calendar',
	cup = 'cup',
	refresh = 'refresh',
	database = 'database',
	report = 'report',
	sparkles = 'sparkles',
	lightning = 'lightning',
	notificationBell = 'notification-bell',
	questionMark = 'question-mark',
	headphones = 'headphones',
	fire = 'fire',
	pencil = 'pencil',
	speechBubble = 'speech-bubble',
	academicCap = 'academic-cap',
}

export const chatMenuIconsOrdered: ChatMenuIcon[] = [
	ChatMenuIcon.calendar,
	ChatMenuIcon.cup,
	ChatMenuIcon.refresh,
	ChatMenuIcon.database,
	ChatMenuIcon.report,
	ChatMenuIcon.sparkles,
	ChatMenuIcon.lightning,
	ChatMenuIcon.notificationBell,
	ChatMenuIcon.questionMark,
	ChatMenuIcon.headphones,
	ChatMenuIcon.fire,
	ChatMenuIcon.speechBubble,
	ChatMenuIcon.academicCap,
];

type ItoAssistantConfigCaseType = {
	condition: ExpressionBooleanType[];
	assistantConfig: ItoAssistantConfigStub;
};

type FunctionalMessage = {
	label: string;
	message: string;
	icon: Image;
	isVisible: boolean;
};

export type FragmentItoChatType = Document & {
	_type: 'FragmentItoChat';
	homeScreenConfigCases?: ItoAssistantConfigCaseType[];
	homeScreenConfigDefault: ItoAssistantConfigStub;
	chatPlaceholder: string;
	disclaimerText: InlineContentType;
	reportMessageTitle: string;
	defaultSupportText: string;
	chatActions?: SendToChatOption[];
	actionOptions: ActionOption[];
	contactSupportCTA: string;
	findHelplineCTA: string;
	checkInCTA: string;
	fallbackText: string;
	initialChatLoadingText: string;
	threadError: {
		errors: {
			code: string;
			message: string;
			ctaLabel: string;
		}[];
		defaultError: {
			message: string;
			ctaLabel: string;
		};
	};
	freeMessagesLimit: {
		freeResponsesLeft: InlineContentType;
		noFreeResponsesLeft: string;
		startTrial: string;
		startTrialNoResponsesLeft: string;
	};
	functionalMessages: FunctionalMessage[];
};

type SendToChatOption = BaseChatMenuOption;

type ActionOption = BaseChatMenuOption & {
	action: [ActionType];
};

type BaseChatMenuOption = {
	text: string;
	icon: ChatMenuIcon;
};

export default defineType({
	name: 'FragmentItoChat',
	type: 'document',
	fields: [
		{
			name: 'homeScreenConfigCases',
			type: 'array',
			description:
				'To override the default home screen assistant config - the first matching case will be used',
			of: [
				{
					type: 'object',
					fields: [
						{
							name: 'condition',
							type: 'array',
							validation: (r) => r.required().min(1),
							of: arrayOfTypesBoolean,
							description: 'Condition to determine if this case should be used',
						},
						{
							name: 'assistantConfig',
							type: 'reference',
							description: 'Home screen assistant config if this case is used',
							validation: (r) => r.required(),
							to: [{ type: 'ItoAssistantConfig' }],
						},
					],
					preview: {
						select: {
							condition: 'condition',
						},
						prepare: ({ condition }: Partial<ItoAssistantConfigCaseType>) => ({
							subtitle: renderExpression({
								_type: 'ExpressionLogicalAnd',
								operands: condition ?? [],
							}),
						}),
					},
				},
			],
		},
		{
			name: 'homeScreenConfigDefault',
			type: 'reference',
			description: 'The default assistant config to use for the home screen',
			validation: (r) => r.required(),
			to: [{ type: 'ItoAssistantConfig' }],
		},
		{
			name: 'chatPlaceholder',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'disclaimerText',
			type: 'InlineContent',
			validation: (r) => r.required(),
		},
		{
			name: 'defaultSupportText',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'reportMessageTitle',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'contactSupportCTA',
			description:
				'The text of the button that triggers the contact support action',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'findHelplineCTA',
			description: 'The text of the button that redirects to findahelpline.com',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'checkInCTA',
			description: 'The text of Plan check-in button',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'fallbackText',
			type: 'string',
			description:
				'Message to show if OpenAI is down and we need a default home screen',
			validation: (r) => r.required(),
		},
		{
			name: 'initialChatLoadingText',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'chatActions',
			type: 'array',
			// TODO make this field optional once min version is v1.151.2
			validation: (r) => r.required().min(1),
			of: [
				{
					type: 'object',
					fields: [
						{ name: 'text', type: 'string', validation: (r) => r.required() },
						{
							name: 'icon',
							type: 'string',
							options: {
								list: chatMenuIconsOrdered,
							},
							validation: (r) => r.required(),
						},
					],
				},
			],
		},
		{
			name: 'actionOptions',
			type: 'array',
			validation: (r) => r.required().min(1),
			of: [
				{
					type: 'object',
					fields: [
						{ name: 'text', type: 'string', validation: (r) => r.required() },
						{
							name: 'icon',
							type: 'string',
							options: {
								list: chatMenuIconsOrdered,
							},
							validation: (r) => r.required(),
						},
						{
							name: 'action',
							type: 'array',
							validation: (r) => r.max(1),
							of: Actions,
						},
					],
				},
			],
		},
		{
			name: 'threadError',
			type: 'object',
			validation: (r) => r.required(),
			fields: [
				{
					name: 'errors',
					type: 'array',
					description: 'Map of error codes to their corresponding messages',
					validation: (r) => r.required().min(1),
					of: [
						{
							type: 'object',
							fields: [
								{
									name: 'code',
									type: 'string',
									description:
										'Unique identifier for this error type (e.g. "timeout_error")',
									validation: (r) => r.required(),
								},
								{
									name: 'message',
									type: 'string',
									description: 'The error message shown when this error occurs',
									validation: (r) => r.required(),
								},
								{
									name: 'ctaLabel',
									type: 'string',
									description:
										'The text shown on the retry button for this error',
									validation: (r) => r.required(),
								},
							],
						},
					],
				},
				{
					name: 'defaultError',
					type: 'object',
					description:
						'Default error message used when no specific error code matches',
					validation: (r) => r.required(),
					fields: [
						{
							name: 'message',
							type: 'string',
							description:
								'The default error message shown when there is a problem loading the response',
							initialValue:
								'There was a problem loading the response. Please try again shortly.',
							validation: (r) => r.required(),
						},
						{
							name: 'ctaLabel',
							type: 'string',
							description: 'The default text shown on the retry button',
							initialValue: 'Try again',
							validation: (r) => r.required(),
						},
					],
				},
			],
		},
		{
			name: 'freeMessagesLimit',
			type: 'object',
			fields: [
				{
					name: 'freeResponsesLeft',
					type: 'InlineContent',
					validation: (r) => r.required(),
				},
				{
					name: 'noFreeResponsesLeft',
					type: 'string',
					validation: (r) => r.required(),
				},
				{
					name: 'startTrial',
					type: 'string',
					validation: (r) => r.required(),
				},
				{
					name: 'startTrialNoResponsesLeft',
					type: 'string',
					validation: (r) => r.required(),
				},
			],
		},
		{
			name: 'functionalMessages',
			type: 'array',
			validation: (r) =>
				r
					.required()
					.min(1)
					.custom((value: FunctionalMessage[] | undefined) => {
						if (!value) return true;
						const labels = value.map((item) => item.label);
						const uniqueLabels = new Set(labels);
						if (labels.length !== uniqueLabels.size) {
							return 'Each functional message must have a unique label';
						}
						return true;
					}),
			of: [
				{
					type: 'object',
					fields: [
						{
							name: 'label',
							type: 'string',
							description: 'The label shown on the tabs',
							validation: (r) => r.required(),
						},
						{
							name: 'message',
							type: 'string',
							description: 'The message that will be sent in the chat',
							validation: (r) => r.required(),
						},
						{
							name: 'icon',
							type: 'image',
							validation: (r) => {
								return r.required().custom<SanityImageObject>((value) => {
									if (value && getExtension(value.asset._ref) !== 'svg') {
										return 'Only SVG images are allowed';
									}
									return true;
								});
							},
						},
						{
							name: 'isVisible',
							type: 'boolean',
							description:
								'Determines whether this functional message will be displayed on the screen',
							validation: (r) => r.required(),
							initialValue: true,
						},
					],
					preview: {
						select: {
							label: 'label',
						},
						prepare: (value: Record<string, string>) => ({
							title: value.label ?? 'Functional Message',
						}),
					},
				},
			],
		},
	],
});
