import { IoMdGitBranch } from '@react-icons/all-files/io/IoMdGitBranch';
import { defineType } from '@sanity/types';

import { Document } from './_types';
import { validateSnakeCase } from './_utils';

export type MediaTagType = Document & {
	_type: 'MediaTag';
	key: string;
	value: string;
	icon?: 'heart' | 'bolt';
};

export default defineType({
	name: 'MediaTag',
	type: 'document',
	icon: IoMdGitBranch,
	fields: [
		{
			name: 'key',
			type: 'string',
			validation: (r) => validateSnakeCase(r).required(),
		},
		{
			name: 'value',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'icon',
			type: 'string',
			options: {
				list: [
					{ title: 'Heart', value: 'heart' },
					{ title: 'Bolt', value: 'bolt' },
				],
			},
		},
	],
	preview: {
		select: {
			key: 'key',
			value: 'value',
		},
		prepare: ({ key, value }) => ({
			title: `${key} - ${value}`,
		}),
	},
});
