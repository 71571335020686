import { defineType, Image, TitledListValue } from '@sanity/types';

export type FragmentCoachesType = {
	_type: 'FragmentCoaches';
	images: {
		optionKey: string;
		image: Image;
	}[];
};

const COACHES_OPTIONS_LIST: TitledListValue<string>[] = [
	{ title: 'Cassie', value: 'cassandra' },
	{ title: 'Rachel', value: 'rachel' },
	{ title: 'Jordan', value: 'jordan' },
	{ title: 'Matt', value: 'mateo' },
];

export default defineType({
	name: 'FragmentCoaches',
	type: 'document',
	fields: [
		{
			name: 'images',
			type: 'array',
			validation: (r) =>
				r
					.required()
					.length(COACHES_OPTIONS_LIST.length)
					.custom((value: { optionKey: string; image: any }[]) => {
						const optionKeys = new Set(
							value.map((v) => v.optionKey).filter(Boolean),
						);
						if (optionKeys.size !== COACHES_OPTIONS_LIST.length) {
							return 'Every coach requires an image / No duplicates';
						}
						return true;
					}),
			of: [
				{
					type: 'object',
					fields: [
						{
							name: 'optionKey',
							type: 'string',
							description: 'This should be one of the keys from the coach poll',
							options: {
								list: COACHES_OPTIONS_LIST,
							},
							validation: (r) => r.required(),
						},
						{
							name: 'image',
							type: 'image',
							validation: (r) => r.required(),
						},
					],
				},
			],
		},
	],
});
