import { defineType } from '@sanity/types';

import { Document } from '../_types';
import { validateSnakeCase } from '../_utils';
import {
	arrayOfTypesBoolean,
	ExpressionBooleanType,
} from '../expressions/_types';
import { renderExpression } from '../expressions/_utils';
import { ItoPlanAssistantConfigStub } from '../ItoPlanConfig';
import { ProgramActivityType } from '../program/ProgramActivity';

export type FragmentItoPlanConfigType = Document & {
	_type: 'FragmentItoPlanConfig';
	planConfigCases?: ItoPlanAssistantConfigCaseType[];
	planConfigDefault: ItoPlanAssistantConfigStub;
	strategyActivityMap: Strategy[];
};

type ItoPlanAssistantConfigCaseType = {
	condition: ExpressionBooleanType[];
	planConfig: ItoPlanAssistantConfigStub;
};

type Strategy = {
	strategyKey: string;
	userGoal: string[];
	activities: ProgramActivityType[];
	strategyObjective: string;
	planningObjective?: string;
};

export default defineType({
	name: 'FragmentItoPlanConfig',
	type: 'document',
	fields: [
		{
			name: 'planConfigCases',
			type: 'array',
			description:
				'To override the default plan assistant config - the first matching case will be used',
			of: [
				{
					type: 'object',
					fields: [
						{
							name: 'condition',
							type: 'array',
							validation: (r) => r.required().min(1),
							of: arrayOfTypesBoolean,
							description: 'Condition to determine if this case should be used',
						},
						{
							name: 'assistantConfig',
							type: 'reference',
							description: 'Plan assistant config if this case is used',
							validation: (r) => r.required(),
							to: [{ type: 'ItoPlanConfig' }],
						},
					],
					preview: {
						select: {
							condition: 'condition',
						},
						prepare: ({
							condition,
						}: Partial<ItoPlanAssistantConfigCaseType>) => ({
							subtitle: renderExpression({
								_type: 'ExpressionLogicalAnd',
								operands: condition ?? [],
							}),
						}),
					},
				},
			],
		},
		{
			name: 'planConfigDefault',
			type: 'reference',
			description: 'The default assistant config to use for the plan',
			validation: (r) => r.required(),
			to: [{ type: 'ItoPlanConfig' }],
		},
		{
			name: 'strategyActivityMap',
			type: 'array',
			validation: (r) => r.required(),
			of: [
				{
					type: 'object',
					fields: [
						{
							name: 'strategyKey',
							type: 'string',
							description:
								'This should be one of the keys from the plan strategies poll e.g. Erection issues strategies',
							validation: (r) => validateSnakeCase(r).required(),
						},
						{
							name: 'userGoal',
							type: 'array',
							description:
								'The user goal(s) that this strategy is designed to help with. Refers to the options for "What do you want to achieve with Mojo?" poll',
							initialValue: ['erection_issues', 'rapid_ejaculation'],
							of: [
								{
									type: 'string',
									validation: (r) => validateSnakeCase(r),
									options: {
										list: [
											{
												title: 'erection_issues',
												value: 'erection_issues',
											},
											{
												title: 'rapid_ejaculation',
												value: 'rapid_ejaculation',
											},
										],
									},
								},
							],
							validation: (r) => r.required().unique(),
						},
						{
							name: 'activities',
							type: 'array',
							description: 'The activities that are part of this strategy',
							of: [
								{
									type: 'reference',
									to: [{ type: 'ProgramActivity' }],
								},
							],
							validation: (r) => r.required(),
						},
						{
							name: 'strategyObjective',
							type: 'string',
							validation: (r) => r.required(),
						},
						{
							title: 'Planning Guidance',
							name: 'planningObjective',
							type: 'string',
						},
					],
					preview: {
						select: {
							title: 'strategyKey',
							subtitle: 'strategyObjective',
						},
					},
				},
			],
		},
	],
});
