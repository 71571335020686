import { HiOutlineShieldExclamation } from '@react-icons/all-files/hi/HiOutlineShieldExclamation';
import { defineField, defineType } from '@sanity/types';

import { previewNamedBlockContent, validateSnakeCase } from './_utils';
import { InlineContentType } from './block/InlineContent';
import { Actions, ActionType } from './fields/actions/Actions';

type ErrorDefinition = {
	code: string;
	type: 'generic' | 'connection';
	title?: InlineContentType;
	message: InlineContentType;
	actions?: ActionType[];
	ctaLabel?: string;
};

export type ErrorMessagesType = {
	_type: 'ErrorMessages';
	defaultError: Omit<ErrorDefinition, 'code'>;
	defaultTitle: InlineContentType;
	errors: ErrorDefinition[];
};

const errorFields = [
	defineField({
		name: 'type',
		type: 'string',
		validation: (r) => r.required(),
		initialValue: 'generic',
		options: {
			list: [
				{ value: 'generic', title: 'Generic error' },
				{ value: 'connection', title: 'Connection issue' },
			],
		},
	}),
	defineField({
		name: 'title',
		type: 'InlineContent',
		validation: (r) => r.max(1),
	}),
	defineField({
		name: 'message',
		type: 'InlineContent',
		validation: (r) => r.required().min(1).max(3),
	}),
	defineField({
		name: 'actions',
		type: 'array',
		validation: (r) => r.max(3),
		of: Actions,
	}),
	defineField({
		name: 'ctaLabel',
		type: 'string',
	}),
];

export default defineType({
	name: 'ErrorMessages',
	type: 'document',
	icon: HiOutlineShieldExclamation,
	groups: [
		{
			name: 'defaults',
			title: 'Defaults',
		},
		{
			name: 'mapping',
			title: 'Mapping',
			default: true,
		},
	],
	fields: [
		{
			name: 'defaultError',
			group: 'defaults',
			type: 'object',
			description: 'Used if the error code is not listed below',
			validation: (r) => r.required(),
			fields: errorFields,
		},
		{
			name: 'defaultTitle',
			group: 'defaults',
			type: 'InlineContent',
			description: 'Used when an error code below does not have a title',
			validation: (r) => r.required().length(1),
		},
		{
			name: 'errors',
			group: 'mapping',
			type: 'array',
			of: [
				{
					type: 'object',
					fields: [
						{
							name: 'code',
							type: 'string',
							validation: (r) => validateSnakeCase(r).required(),
						},
						...errorFields,
					],
					preview: previewNamedBlockContent('code', 'message'),
				},
			],
			validation: (r) =>
				r
					.required()
					.min(1)
					.custom<ErrorMessagesType['errors']>((value) => {
						if (!value) return true;
						if (value.length !== new Set(value.map((e) => e.code)).size) {
							return 'Error codes must be unique';
						}
						if (!value.slice(1).every((e, i) => e.code > value[i]!.code)) {
							return 'Error codes must be in alphabetical order';
						}
						return true;
					}),
		},
	],
});
