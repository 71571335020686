import { FaRobot } from '@react-icons/all-files/fa6/FaRobot';
import { defineField, defineType, TitledListValue } from '@sanity/types';

import { Document } from './_types';
import { ksuidIdField, validateSnakeCase } from './_utils';
import { ItoLLMConfigType } from './ItoLLMConfig';

export enum ThreadMode {
	Singleton = 'singleton',
	TrackingDate = 'tracking_date',
	OneOff = 'one_off',
}

enum EntryType {
	Action = 'action',
	Activities = 'activities',
	Poll = 'poll',
	MediaItems = 'media_items',
	CopyableText = 'copiable_text',
}

enum ActionType {
	Navigate = 'navigate',
	ContactSupport = 'contact_support',
	HelplineReferral = 'helpline_referral',
	ContinueFlow = 'continue_flow',
	CheckIn = 'check_in',
}

enum Tool {
	RecordPollResponse = 'record_poll_response',
	UpsertMemory = 'upsert_memory',
	AvailableMediaItems = 'available_media_items',
}

const llmConfigField = defineField({
	name: 'llmConfig',
	type: 'reference',
	validation: (r) => r.required(),
	to: [{ type: 'ItoLLMConfig' }],
});

const allowedEntryTypesField = defineField({
	name: 'allowedEntryTypes',
	type: 'array',
	description: 'Types of entries this configuration can generate',
	initialValue: [EntryType.Activities, EntryType.Action],
	of: [
		{
			type: 'string',
			options: {
				list: Object.values(EntryType).map((type) => ({
					title: type,
					value: type,
				})),
			},
		},
	],
	validation: (r) =>
		r.custom((entryTypes?: EntryType[]) => {
			if (!entryTypes) return true;

			if (entryTypes.some((entry) => !entry)) {
				return 'Empty entry types not allowed';
			}

			const uniqueTypes = new Set(entryTypes);
			if (entryTypes.length !== uniqueTypes.size) {
				return 'Entry types must be unique - no duplicates allowed';
			}
			return true;
		}),
});

const allowedActionTypesField = defineField({
	name: 'allowedActionTypes',
	type: 'array',
	description: 'Types of actions this configuration can trigger',
	initialValue: [
		ActionType.Navigate,
		ActionType.ContactSupport,
		ActionType.HelplineReferral,
	],
	of: [
		{
			type: 'string',
			options: {
				list: Object.values(ActionType).map((type) => ({
					title: type,
					value: type,
				})),
			},
		},
	],
	validation: (r) =>
		r.custom((actionTypes?: ActionType[]) => {
			if (!actionTypes) return true;

			if (actionTypes.some((action) => !action)) {
				return 'Empty action types not allowed';
			}

			const uniqueTypes = new Set(actionTypes);
			if (actionTypes.length !== uniqueTypes.size) {
				return 'Action types must be unique - no duplicates allowed';
			}
			return true;
		}),
});

const allowedToolsField = defineField({
	name: 'allowedTools',
	type: 'array',
	description: 'The tools this agent is allowed to call',
	initialValue: [Tool.UpsertMemory, Tool.AvailableMediaItems],
	of: [
		{
			type: 'string',
			options: {
				list: Object.values(Tool).map((t) => ({
					title: t,
					value: t,
				})),
			},
		},
	],
});

const allowSuggestedRepliesField = defineField({
	name: 'allowSuggestedReplies',
	type: 'boolean',
	description: 'Whether this configuration can suggest replies',
	validation: (r) => r.required(),
});

const allowFunctionalMessagesField = defineField({
	name: 'allowFunctionalMessages',
	type: 'boolean',
	description: 'Whether this configuration can suggest functional messages',
	validation: (r) => r.required(),
});

const chatNodeConfigFields = [
	llmConfigField,
	allowSuggestedRepliesField,
	allowFunctionalMessagesField,
	allowedEntryTypesField,
	allowedActionTypesField,
	allowedToolsField,
];

const CHAT_ENTRY_TYPE_LIST: TitledListValue<string>[] = [
	{ title: 'Opened app', value: 'opened_app' },
	{ title: 'Activity completed', value: 'completed_activity' },
	{ title: 'Routine updated', value: 'routine_updated' },
];

type ChatNodeConfig = {
	llmConfig: ItoLLMConfigType;
	allowSuggestedReplies: boolean;
	allowFunctionalMessages: boolean;
	allowedEntryTypes?: `${EntryType}`[];
	allowedActionTypes?: `${ActionType}`[];
	allowedTools?: `${Tool}`[];
};

export type ItoAssistantConfigStub = {
	id: string;
	threadConfig: {
		purpose: string;
		mode: `${ThreadMode}`;
	};
};

export type ItoAssistantConfigType = Document & {
	_type: 'ItoAssistantConfig';
	id: string;
	graph: string;
	threadConfig: {
		purpose: string;
		mode: `${ThreadMode}`;
	};
	nodeConfig: {
		chat: ChatNodeConfig;
		safety: {
			llmConfig: ItoLLMConfigType;
		};
		threadSummary?: {
			llmConfig: ItoLLMConfigType;
		};
		tools?: {
			allowedPolls?: { id: string }[];
		};
	};
};

export default defineType({
	name: 'ItoAssistantConfig',
	type: 'document',
	icon: FaRobot,
	fields: [
		ksuidIdField('itoconf', true),
		{
			name: 'graph',
			type: 'string',
			description: 'The graph to use for this configuration',
			validation: (r) => r.required(),
			initialValue: 'example',
			readOnly: true,
		},
		{
			name: 'threadConfig',
			type: 'object',
			description: 'Configuration for thread management',
			validation: (r) => r.required(),
			initialValue: {
				purpose: 'home_screen',
				mode: ThreadMode.Singleton,
			},
			fields: [
				{
					name: 'purpose',
					type: 'string',
					description:
						'The purpose of the thread (used in key generation) (e.g. "home_screen")',
					validation: (r) => validateSnakeCase(r).required(),
				},
				{
					name: 'mode',
					type: 'string',
					description: 'How thread keys should be generated',
					validation: (r) => r.required(),
					options: {
						list: Object.values(ThreadMode).map((mode) => ({
							title: mode,
							value: mode,
						})),
					},
				},
			],
		},
		{
			name: 'nodeConfig',
			type: 'object',
			description: 'Configuration for individual nodes',
			validation: (r) => r.required(),
			fields: [
				{
					name: 'chat',
					title: 'Default chat config',
					description: `This is the config that is used if there isn't a more specific routing match.`,
					type: 'object',
					options: { collapsible: true },
					validation: (r) => r.required(),
					fields: chatNodeConfigFields,
				},
				{
					name: 'chatByEntryType',
					title: 'Chat config by entry type',
					description:
						'This allows overriding the prompt config for a specific entry type i.e. message routing',
					type: 'array',
					validation: (r) =>
						r.custom(
							(value: { entryType: string; config: any }[] | undefined) => {
								if (!value) {
									// no config override is allowed
									return true;
								}
								const optionKeys = new Set(
									value.map((v) => v.entryType).filter(Boolean),
								);
								if (optionKeys.size !== value.length) {
									return 'No duplicates';
								}
								return true;
							},
						),
					of: [
						{
							type: 'object',
							fields: [
								{
									name: 'entryType',
									type: 'string',
									options: {
										list: CHAT_ENTRY_TYPE_LIST,
									},
									validation: (r) => r.required(),
								},
								{
									name: 'config',
									type: 'object',
									validation: (r) => r.required(),
									fields: chatNodeConfigFields,
								},
							],
						},
					],
				},
				{
					name: 'safety',
					type: 'object',
					validation: (r) => r.required(),
					fields: [llmConfigField],
				},
				{
					name: 'threadSummary',
					type: 'object',
					description: 'Leave empty to disable in-thread summarization',
					fields: [llmConfigField],
				},
				{
					name: 'tools',
					type: 'object',
					fields: [
						defineField({
							name: 'allowedPolls',
							type: 'array',
							description:
								'The polls this agent is allowed to record responses for',
							of: [
								{
									type: 'reference',
									to: [{ type: 'Poll' }],
								},
							],
						}),
					],
				},
			],
		},
	],
	preview: {
		select: {
			id: 'id',
			graph: 'graph',
			purpose: 'threadConfig.purpose',
		},
		prepare({ id, graph, purpose }) {
			return {
				title: purpose,
				subtitle: `${graph} (${id})`,
			};
		},
	},
});
