import { BiCategoryAlt } from '@react-icons/all-files/bi/BiCategoryAlt';
import { getExtension } from '@sanity/asset-utils';
import { SanityImageObject } from '@sanity/image-url/lib/types/types';
import { defineType } from '@sanity/types';

import { Document, Image } from './_types';
import { ksuidIdField, slugify } from './_utils';
import { InlineContentWrapperType } from './block/InlineContentWrapper';
import { LibraryGroupType } from './LibraryGroup';
import { MediaItemType } from './MediaItem';
import { MediaTagType } from './MediaTag';
import { ProgramActivityType } from './program/ProgramActivity';

export type LibraryFilterType = {
	key: string;
	pillar?: string;
	tag?: MediaTagType;
};

export type LibraryCategoryType = Document & {
	_type: 'LibraryCategory';
	slug: {
		current: string;
		_type: 'slug';
	};
	entrypointTitle: string;
	entrypointDescription: InlineContentWrapperType;
	entrypointIcon: Image;
	entrypointIconBackground: Image;
	title: string;
	description: InlineContentWrapperType;
	filters: LibraryFilterType[];
	items: (MediaItemType | LibraryGroupType | ProgramActivityType)[];
	id: string;
};

export default defineType({
	name: 'LibraryCategory',
	type: 'document',
	icon: BiCategoryAlt,
	fields: [
		ksuidIdField('libcatb', true),
		{
			name: 'slug',
			type: 'slug',
			validation: (r) => r.required(),
			options: {
				source: 'name',
				slugify,
			},
		},
		{
			name: 'entrypointTitle',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'entrypointDescription',
			type: 'reference',
			to: [{ type: 'InlineContentWrapper' }],
			validation: (r) => r.required(),
		},
		{
			name: 'entrypointIcon',
			type: 'image',
			validation: (r) => {
				return r.required().custom<SanityImageObject>((value) => {
					if (value && getExtension(value.asset._ref) !== 'svg') {
						return 'Only SVG images are allowed';
					}
					return true;
				});
			},
		},
		{
			name: 'entrypointIconBackground',
			type: 'image',
			validation: (r) => {
				return r.required().custom<SanityImageObject>((value) => {
					if (value && getExtension(value.asset._ref) !== 'svg') {
						return 'Only SVG images are allowed';
					}
					return true;
				});
			},
		},
		{
			name: 'title',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'description',
			type: 'reference',
			to: [{ type: 'InlineContentWrapper' }],
			validation: (r) => r.required(),
		},
		{
			name: 'filters',
			description:
				'Filters to apply to the items in the category, this will render the tags',
			type: 'array',
			of: [
				{
					type: 'object',
					fields: [
						{
							type: 'string',
							name: 'key',
							options: { list: ['pillars', 'tags'] },
						},
						{
							name: 'pillar',
							type: 'SQScorePillar',
							hidden: ({ parent }) => parent?.key !== 'pillars',
						},
						{
							name: 'tag',
							type: 'reference',
							to: [{ type: 'MediaTag' }],
							hidden: ({ parent }) => parent?.key !== 'tags',
						},
					],
					preview: {
						select: {
							key: 'key',
							pillarValue: 'pillar',
							tagValue: 'tag.key',
						},
						prepare: ({ key, pillarValue, tagValue }) => ({
							title: `${key} - ${key === 'pillars' ? pillarValue : tagValue}`,
						}),
					},
				},
			],
			validation: (r) =>
				r.custom(
					(
						filters: {
							key: string;
							pillar?: string;
							tag?: MediaTagType & { _ref: string };
						}[],
					) => {
						if (!filters || filters.length <= 1) return true;

						const firstItemKey = filters[0]?.key;

						// Check if all items have the same key as the first one
						const inconsistentItem = filters.find(
							(filter) => filter.key !== firstItemKey,
						);

						if (inconsistentItem) {
							return `All filters must be of the same type. You've selected "${firstItemKey}" first, so all items must be "${firstItemKey}"`;
						}
						if (firstItemKey === 'pillars') {
							// For pillars, check uniqueness of pillar values
							const pillarValues = filters.map((f) => f.pillar);
							const uniquePillarValues = new Set(pillarValues);

							if (pillarValues.length !== uniquePillarValues.size) {
								return 'Each pillar can only be added once to the filters';
							}
						} else if (firstItemKey === 'tags') {
							// For tags, check uniqueness of tag references

							const tagIds = filters.map((f) => f.tag?._ref);
							const uniqueTagIds = new Set(tagIds);

							if (tagIds.length !== uniqueTagIds.size) {
								return 'Each tag can only be added once to the filters';
							}
						}

						return true;
					},
				),
		},
		{
			name: 'items',
			type: 'array',
			of: [
				{
					type: 'reference',
					to: [
						{ type: 'MediaItem' },
						{ type: 'LibraryGroup' },
						{ type: 'ProgramActivity' },
					],
				},
			],
			validation: (r) => r.required().min(1),
		},
	],
	preview: {
		select: {
			title: 'title',
		},
	},
});
