import { defineType } from '@sanity/types';

import { ActionBaseFields, ActionBaseType } from './_utils';

export type UploadImageActionType = ActionBaseType & {
	_type: 'uploadImage';
};

export default defineType({
	name: 'uploadImage',
	type: 'object',
	fields: [
		...ActionBaseFields({
			defaultTitle: 'Upload image',
		}),
	],
});
