import { HiOutlineVolumeUp } from '@react-icons/all-files/hi/HiOutlineVolumeUp';
import { defineType } from '@sanity/types';

import { Document } from './_types';
import { ksuidIdField } from './_utils';
import { AudioType } from './Audio';

export enum MediaType {
	audio = 'audio',
	// TODO: add video when needed
	// video = 'video',
}

export type MediaItemVariantType = Document & {
	_type: 'MediaItemVariant';
	id: string;
	mediaType: MediaType;
	duration: number;
	media: AudioType;
};

export default defineType({
	name: 'MediaItemVariant',
	type: 'document',
	icon: HiOutlineVolumeUp,
	fields: [
		ksuidIdField('mediaitemvar', true),
		{
			name: 'mediaType',
			type: 'string',
			options: {
				list: Object.values(MediaType).map((type) => ({
					title: type.charAt(0).toUpperCase() + type.slice(1),
					value: type,
				})),
			},
			validation: (r) => r.required(),
		},
		{
			name: 'media',
			type: 'reference',
			// Add { type: 'Video' } when we have Video supported
			to: [{ type: 'Audio' }],
			validation: (r) => r.required(),
		},
	],
	preview: {
		select: {
			title: 'media.name',
			subtitle: 'media.duration',
		},
		prepare({ title, subtitle }) {
			return {
				title: title || 'Untitled',
				subtitle: subtitle || 'No duration set',
			};
		},
	},
});
