import { defineType } from '@sanity/types';

import { Document } from '../_types';

export type ItoPolls = {
	pollId: string;
	hideResults: boolean;
};

export type FragmentItoPollsType = Document & {
	_type: 'FragmentItoPolls';
	polls: ItoPolls[];
	skippingMessageText: string;
	nextButtonTitle: string;
	skipButtonTitle: string;
};

export default defineType({
	name: 'FragmentItoPolls',
	type: 'document',
	fields: [
		{
			name: 'polls',
			type: 'array',
			validation: (r) => r.required(),
			of: [
				{
					name: 'poll',
					type: 'object',
					fields: [
						{
							name: 'pollId',
							type: 'string',
							validation: (r) => r.required(),
						},
						{
							name: 'hideResults',
							type: 'boolean',
							initialValue: true,
						},
					],
					preview: {
						select: {
							title: 'pollId',
						},
					},
				},
			],
		},
		{
			name: 'skippingMessageText',
			type: 'string',
			initialValue: 'Skipped',
		},
		{
			name: 'nextButtonTitle',
			type: 'string',
			initialValue: 'Save',
			validation: (r) => r.required(),
		},
		{
			name: 'skipButtonTitle',
			type: 'string',
			initialValue: 'Skip',
			validation: (r) => r.required(),
		},
	],
});
