import { IoMdGitBranch } from '@react-icons/all-files/io/IoMdGitBranch';
import { defineType } from '@sanity/types';

import { Document, Image } from './_types';
import { ksuidIdField } from './_utils';
import { InlineContentWrapperType } from './block/InlineContentWrapper';
import { MediaItemType } from './MediaItem';
import { MediaTagType } from './MediaTag';
import { ProgramActivityType } from './program/ProgramActivity';

export type LibraryGroupType = Document & {
	_type: 'LibraryGroup';
	slug: {
		_type: 'slug';
		current: string;
	};
	title: string;
	description: InlineContentWrapperType;
	image: Image;
	showNumberedItems: boolean;
	tags: MediaTagType[];
	items: (MediaItemType | ProgramActivityType)[];
};

export default defineType({
	name: 'LibraryGroup',
	type: 'document',
	icon: IoMdGitBranch,
	fields: [
		ksuidIdField('libcatl', true),
		{
			name: 'slug',
			type: 'slug',
			validation: (r) => r.required(),
		},
		{
			name: 'title',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'description',
			type: 'reference',
			to: [{ type: 'InlineContentWrapper' }],
			validation: (r) => r.required(),
		},
		{
			name: 'showNumberedItems',
			type: 'boolean',
			initialValue: false,
			description:
				'If true, the items will be displayed with a number in front of them',
		},
		{
			name: 'image',
			type: 'image',
			description:
				'Make sure to set the hotspot by clicking the crop icon on the image',
			validation: (r) => r.required(),
			options: {
				// https://www.sanity.io/docs/image-type#hotspot-3e6da78954a8
				hotspot: true,
			},
		},
		{
			name: 'tags',
			type: 'array',
			of: [{ type: 'reference', to: [{ type: 'MediaTag' }] }],
			validation: (r) => r.unique(),
		},
		{
			name: 'items',
			type: 'array',
			of: [
				{
					type: 'reference',
					to: [{ type: 'MediaItem' }, { type: 'ProgramActivity' }],
				},
			],
			validation: (r) => r.required().min(1),
		},
	],
	preview: {
		select: {
			title: 'title',
			items: 'items',
		},
		prepare({ title, items }) {
			return {
				title,
				subtitle: `${items.length} items`,
			};
		},
	},
});
