import { defineType } from '@sanity/types';

import { Document } from '../_types';
import { LibraryCategoryType } from '../LibraryCategory';

//TODO: remove once we are moving to libraryCategory
const exploreCategories = [
	'erotic_stories',
	'meditations',
	'podcasts',
	'exercises',
] as const;

export type ExploreCategory = (typeof exploreCategories)[number];

export type FragmentExploreType = Document & {
	_type: 'FragmentExplore';
	title: string;
	categories: Array<LibraryCategoryType>;
	subtitle?: string;
};

export default defineType({
	name: 'FragmentExplore',
	type: 'document',
	fields: [
		{
			name: 'title',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'subtitle',
			type: 'string',
		},
		{
			name: 'categories',
			type: 'array',
			description:
				'Categories to display in the explore section. The order of the categories will be the order they are displayed in the app.',
			validation: (r) => r.required().min(1),
			of: [
				{
					name: 'LibraryCategory',
					type: 'reference',
					to: [{ type: 'LibraryCategory' }],
				},
			],
		},
	],
});
