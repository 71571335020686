import { defineType } from '@sanity/types';

import { Document, Image } from '../_types';
import { BlockContentType } from '../block/_types';
import { InlineContentType } from '../block/InlineContent';
import { ProgramActivityType } from '../program/ProgramActivity';
import { SQScorePillar } from '../SQScorePillar';

type PillarType = {
	title: InlineContentType;
	subtitle?: InlineContentType;
	content: BlockContentType;
	pillarImage: Image;
	rewardImage: Image;
	updateScoreLink: {
		label: string;
		activity: ProgramActivityType;
	};
};

export type FragmentSQScorePillarsType = Document & {
	_type: 'FragmentSQScorePillars';
	title: InlineContentType;
	scoreBreakdownTooltip: {
		label: string;
		title: InlineContentType;
		content: BlockContentType;
	};
	scorePillars: {
		[key in SQScorePillar]: PillarType;
	};
	updateScoreLink: {
		label: string;
		activity: ProgramActivityType;
	};
};

export default defineType({
	name: 'FragmentSQScorePillars',
	type: 'document',
	fields: [
		{
			name: 'title',
			type: 'InlineContent',
			validation: (r) => r.required(),
		},
		{
			name: 'scorePillars',
			type: 'object',
			fields: Object.values(SQScorePillar).map((pillar) => ({
				name: pillar,
				type: 'object',
				options: {
					collapsible: true,
					collapsed: true,
				},
				fields: [
					{
						name: 'title',
						type: 'InlineContent',
						validation: (r) => r.required(),
					},
					{
						name: 'subtitle',
						type: 'InlineContent',
					},
					{
						name: 'content',
						type: 'BlockContent',
						validation: (r) => r.required(),
					},
					{
						name: 'pillarImage',
						type: 'image',
						validation: (r) => r.required(),
					},
					{
						name: 'rewardImage',
						type: 'image',
						validation: (r) => r.required(),
					},
				],
				validation: (r) => r.required(),
			})),
			validation: (r) => r.required(),
		},
		{
			name: 'scoreBreakdownTooltip',
			type: 'object',
			validation: (r) => r.required(),
			fields: [
				{
					name: 'label',
					type: 'string',
					validation: (r) => r.required(),
				},
				{
					name: 'title',
					type: 'InlineContent',
					validation: (r) => r.required(),
				},
				{
					name: 'content',
					type: 'BlockContent',
					validation: (r) => r.required(),
				},
			],
			preview: {
				select: {
					title: 'title',
					subtitle: 'content',
				},
				prepare: ({ title, subtitle }) => ({
					title,
					subtitle: `${subtitle[0].children[0].text}`,
				}),
			},
		},
		{
			name: 'updateScoreLink',
			type: 'object',
			fields: [
				{
					name: 'label',
					type: 'string',
					validation: (r) => r.required(),
				},
				{
					name: 'activity',
					type: 'reference',
					to: [{ type: 'ProgramActivity' }],
					validation: (r) => r.required(),
				},
			],
		},
	],
});
