import { defineType } from '@sanity/types';

import { Document, Image } from '../_types';
import { InlineContentType } from '../block/InlineContent';

export type FragmentEroticStoriesUpsellType = Document & {
	_type: 'FragmentEroticStoriesUpsell';
	image: Image;
	title: InlineContentType;
	content: InlineContentType;
	ctaText: string;
	skipCta: string;
};

export default defineType({
	name: 'FragmentEroticStoriesUpsell',
	type: 'document',
	fields: [
		{
			name: 'image',
			type: 'image',
		},
		{
			name: 'title',
			type: 'InlineContent',
			validation: (r) => r.required(),
		},
		{
			name: 'content',
			type: 'InlineContent',
			validation: (r) => r.required(),
		},
		{
			name: 'ctaText',
			type: 'string',
			validation: (r) => r.required(),
		},
		{
			name: 'skipCta',
			type: 'string',
			validation: (r) => r.required(),
		},
	],
});
